/* You can add global styles to this file, and also import other style files */
@import "@mhe/mh-icon/src/mhicon.css";
@import "@mhe/he-bs-themes/src/5/up-bs5/index.scss";

@mixin mhe-icon-hover-state {
  background-color: $blue-04 !important;
  color: $white-02;
  border-radius: 0.313rem;
}

@mixin mhe-icon-outline-state {
  background-color: $blue-01 !important;
  outline-style: solid !important;
  outline-color: $blue-01 !important;
  outline-width: 0.125rem !important;
  outline-offset: 0.125rem !important;
}

* {
  font-family: $font-proxima-nova;
}

mjx-container * {
  font-family: MJXZERO, MJXTEX !important;
}

body::-webkit-scrollbar {
  width: 0.625rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.375rem $black-04;
}

body::-webkit-scrollbar-thumb {
  background-color: $grey-07;
  outline: 0.063rem solid $grey-07;
  border-radius: 0.5rem;
}

.hide {
  display: none;
}
.spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 11111;
  top: 0;
  left: 0;
  opacity: 0.5;
  background-color: $black;
  filter: alpha(opacity=50);
}

.fixed-footer {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
  margin-top: 0.625rem;
}

a.kebab-option:hover {
  background-color: $grey-06;
  color: $black-03;
}
a.kebab-option:focus,
a.kebab-option:active {
  background-color: $grey-06;
  border: 0.063rem solid $blue-01;
}

.kebab-option {
  color: inherit;
}

.form-check-input:checked {
  background-color: $blue-01;
  border-color: $blue-01;
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: $blue-01;
  border-color: $blue-01;
}
.link {
  color: $blue-02;
}

.form-check-input:focus {
  border-color: $blue-01 !important;
  box-shadow: 0 0 0 0.05rem $blue-01;
  outline-style: solid !important;
  outline-width: 0.15rem !important;
  color: $white-02;
  outline: $blue-01;
  outline-offset: 0.125 rem;
}

.bs-popover-bottom-end {
  inset: 0px -0.375rem auto auto !important;
}
.bs-popover-top-end {
  inset: auto -0.375rem 0px auto !important;
}
.custom-popover-arrow .popover-arrow {
  left: -0.375rem !important;
}

.popover {
  max-width: 21.875rem;
}

.popover-body {
  padding: 0rem;
}

.mhe-icon {
  background-color: transparent;
  border: none;
  &:hover {
    @include mhe-icon-hover-state;
  }
  &:hover > .mhe-icon,
  &:focus-visible > .mhe-icon,
  &:active > .mhe-icon,
  &:focus-within > .mhe-icon {
    color: $white-02 !important;
  }
  &:focus-visible,
  &:focus-within {
    @include mhe-icon-hover-state;
    @include mhe-icon-outline-state;
  }
  &:focus-within {
    @include mhe-icon-hover-state;
    @include mhe-icon-outline-state;
  }
}

.no-outline {
  outline: none;
}
a {
  color: $blue-01;
}

body {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
}

// Move ng deep css for accordion to here

.activity .accordion .accordion-item {
  border-left: 0;
  border-right: 0;
}
.activity .accordion .accordion-item:first-of-type {
  border-top: 0;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin: 0;
  padding: 0;
}
.activities-container .accordion .accordion-item {
  border: 0;
}
.activity .accordion .accordion-body,
.activities-container .accordion .accordion-body {
  padding-bottom: 0;
  padding-top: 0;
}
.activities-container .accordion .accordion-header,
.activity .accordion-header {
  padding: 0;
}

.form-control:focus {
  border-color: $blue-01 !important;
  box-shadow: none;
}
